import { useState } from "react"

const useToggle = (class1, class2, time) => {

    const [usedClass, setClass] = useState("disabled");
    const [status, setStatus] = useState(false);

    const toggle = () => {
        setClass(status ? class1 : class2);
        if (status)
            setTimeout(setClass, time, "disabled")
        setStatus(!status);
    };

    return [usedClass, toggle];
};

export default useToggle;