import { Link } from 'react-router-dom';
import VideoPlayer from "./VideoPlayer"

const Items = ({ background, type, img_title, title, paragraphs, content, minia, button, link }) => {

    return (
        <div className={`${background} itemBlock itemBlock${type}`}>
            <div data-aos="fade-up">
                {content.slice((content.lastIndexOf('.') - 1 >>> 0) + 2) === 'mp4'
                    ? <video className="itemMedia" controls={true} poster={minia}><source src={content}/></video>
                    : <img className="itemMedia" src={content} alt="" />}
            </div>
            <div className={`itemText itemText${type}`} data-aos="fade-up">
                {img_title && <img src={img_title} alt="" />}
                <h1>{title}</h1>
                {paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
                {button
                    ? (button.length === 2)
                        ? <VideoPlayer title={button[0]} video={button[1]} minia={minia}/>
                        : <Link to={link}>
                            <button className="videoPlayerButton">{button[0]}</button>
                        </Link>
                    : null}
            </div>
        </div>
    );
};

export default Items;