import { useState, useRef } from 'react'
import { createPortal } from 'react-dom';
import useToggle from "../hooks/useToggle"

const VideoPlayer = ({ title, video, minia }) => {

    /* Player Toggle */

    const [videoClass, setVideoClass] = useState("disabled");
    const [playerClass, playerToggle] = useToggle("videoPlayerBlock slideOut", "videoPlayerBlock slideIn", 750);

    const vidRef = useRef(null);
    const handlePauseVideo = () => {
        vidRef.current.pause();
    }

    return (
        <div>
            <button className="videoPlayerButton" onClick={() => { playerToggle(); setVideoClass("videoPlayerMedia") }}>
                {title}
            </button>
            {createPortal(
                <div className={playerClass}>
                    <video controls={true} ref={vidRef} className={videoClass}><source src={video} /></video>
                    <div className="hamburgerBlock" onClick={() => { handlePauseVideo(); playerToggle(); setVideoClass("disabled") }}>
                        <div className="clicked hamburgerTop"></div>
                        <div className="clicked hamburgerBot"></div>
                    </div>
                </div>, document.body
            )}
        </div>
    );
};

export default VideoPlayer;