import { useTranslation } from "react-i18next";
import Thumbnail from "./Thumbnail";

import stock from '../assets/img/stock1.png'
import flow from '../assets/img/flux1.png'
import space from '../assets/img/space1.png'
import evo from '../assets/img/evo1.png'
import secu from '../assets/img/secu1.png'
import eco from '../assets/img/eco1.png'

const thumbnailData = [
    { imgTitle: stock, title: 'Thumbnail.title', paragraph: 'Thumbnail.paragraph', animation: 'fade-right' },
    { imgTitle: flow, title: 'Thumbnail.title 2', paragraph: 'Thumbnail.paragraph 2', animation: 'fade-down' },
    { imgTitle: space, title: 'Thumbnail.title 3', paragraph: 'Thumbnail.paragraph 3', animation: 'fade-left' },
    { imgTitle: evo, title: 'Thumbnail.title 4', paragraph: 'Thumbnail.paragraph 4', animation: 'fade-right' },
    { imgTitle: secu, title: 'Thumbnail.title 5', paragraph: 'Thumbnail.paragraph 5', animation: 'fade-up' },
    { imgTitle: eco, title: 'Thumbnail.title 6', paragraph: 'Thumbnail.paragraph 6', animation: 'fade-left' },
];

const Thumbs = () => {

    const { t } = useTranslation();

    return (
        <div className="thumbBlock bg_right">
            <div className="thumbBlockTitle">
                <h1 data-aos="fade">{t('Thumbnail.box title')}</h1>
            </div>
            <div className="thumbGrid">
                {thumbnailData.map((thumbnail, index) => (
                    <Thumbnail
                        key={index}
                        img_title={thumbnail.imgTitle}
                        title={t(thumbnail.title)}
                        paragraph={t(thumbnail.paragraph)}
                        animation={thumbnail.animation}
                    />
                ))}
            </div>
        </div>
    );
};

export default Thumbs;