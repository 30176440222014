import { Link } from 'react-router-dom';

const HeadBand = ({ background, title, paragraphs, paragraphs2, btn, link }) => (

    <div className={background}>
        <div className="headBandText" data-aos="fade">
            <h1>{title}</h1>
            {paragraphs &&
            (paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            )))}
            {btn && (
                <Link to={link}>
                    <button data-aos="fade" data-aos-duration="2000">
                        {btn}
                    </button>
                </Link>
            )}
            {paragraphs2 &&
            (paragraphs2.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            )))}
        </div>
    </div>
);

export default HeadBand;