import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import useToggle from "../hooks/useToggle"

import logo from '../assets/img/galam.png'
import Hamburger from "./Hamburger"
import globe from '../assets/img/globe.png'


const Nav = () => {

    /* Language Selector */
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
    };

    const [languageClass, languageToggle] = useToggle("navLanguage fadeUp", "navLanguage fadeDown", 250);

    const renderLanguageOptions = () => (
        <div className={languageClass}>
            <p onClick={() => handleLanguageChange("fr", languageToggle())}>Français</p>
            <p onClick={() => handleLanguageChange("en", languageToggle())}>English</p>
        </div>
    );

    /* Menu Toggle */
    const [visible, setVisible] = useState(false)

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setVisible(currentScrollPos >= 50);

        if (window.innerWidth > 950 ? setVisible(currentScrollPos >= 50) : setVisible(currentScrollPos >= 500));
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll)
    })

    return (
        <div>
            <div className={`navTransparent ${visible && 'navSolid'}`}>
                <NavLink to="/"><img className="navLogo" src={logo} alt="LogoGalam" /></NavLink>
                <ul>
                    <li><NavLink to="/" className={({isActive}) => isActive ? "navActive" : ""}>{t("Home")}</NavLink></li>
                    <li><NavLink to="/usage" className={({isActive}) => isActive ? "navActive" : ""}>{t("Usage")}</NavLink></li>
                    <li><NavLink to="/career" className={({isActive}) => isActive ? "navActive" : ""}>{t("Team")}</NavLink></li>
                </ul>
                <NavLink to="/contact" className={({isActive}) => isActive ? "navActive" : ""}><button className="navButton navContact">{t("Contact us")}</button></NavLink>
                <button className="navButton navSelector" onClick={languageToggle} >
                    <img src={globe} alt="GlobeLanguage" />
                    <p>{localStorage.getItem("lng") ? localStorage.getItem("lng").toUpperCase() : "FR"}</p>
                </button>
                {renderLanguageOptions()}
            </div>
            <Hamburger />
        </div>
    )
}

export default Nav