import { useTranslation } from "react-i18next";
import Videobg from "../components/Videobg"
import HeadBand from "../components/HeadBand"
import Thumbs from '../components/Thumbs';
import Item from '../components/Item';

import introVideoFr from '../assets/vid/trailer_fr.mp4'
import introVideoEn from '../assets/vid/trailer_en.mp4'
import minia from '../assets/img/minia.jpg'
import takone from '../assets/img/tak-one.png'
import image1 from '../assets/img/detour.png'
import image2 from '../assets/img/robot2.jpg'
import videoBHV from '../assets/vid/trailerBHV.mp4'

const Home = () => {

    const i18n = useTranslation();

    return (
        <div>
            <Videobg />
            <HeadBand
                background="bg_left"
                title={i18n.t("Prologue.title")}
                paragraphs={[i18n.t("Prologue.paragraph"), i18n.t("Prologue.paragraph 2")]}
            />
            <Item
                background="bg1"
                type="2"
                img_title={takone}
                title={i18n.t("Image 1.title")}
                paragraphs={[i18n.t("Image 1.paragraph"), i18n.t("Image 1.paragraph 2")]}
                content={(localStorage.getItem("lng") === "fr" ? introVideoFr : introVideoEn)}
                minia={minia}
                button=""
            />
            <Thumbs />
            <Item
                background="bg2"
                type="1"
                title={i18n.t("Video 1.title")}
                paragraphs={[i18n.t("Video 1.paragraph"), i18n.t("Video 1.paragraph 2")]}
                content={image1}
                button={[i18n.t("Use Cases")]}
                link = {"/usage"}
            />
            <Item
                background="bg1"
                type="2"
                title={i18n.t("Image 2.title")}
                paragraphs={[i18n.t("Image 2.paragraph"), i18n.t("Image 2.list"), i18n.t("Image 2.list 2"),
                i18n.t("Image 2.list 3"), i18n.t("Image 2.paragraph 2")]}
                content={image2}
                minia={image2}
                button={[i18n.t("Image 2.video link"), videoBHV]}
            />
            <HeadBand
                background="bg_right"
                title={i18n.t("Interested.title")}
                paragraphs={[i18n.t("Interested.paragraph")]}
                btn={i18n.t("Contact us")}
                link="/contact"
            />
        </div>
    );
};

export default Home;