import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Slider from '../components/TestSlider';
import FirstSlide from "../components/FirstSlide";

import BufferONE from '../assets/img/Buffer-one.png';
import PickONE from '../assets/img/Pick-one.png';
import CrossONE from '../assets/img/Cross-one.png';
import StoreONE from '../assets/img/Store-one.png';

const Usage = () => {

    const i18n = useTranslation();

    const [currentSlide, setCurrentSlide] = useState(0); // Ajoutez un état pour suivre l'indice de la diapositive actuelle

    const handleSlideChange = (index) => {
        setCurrentSlide(index); // Mettez à jour l'indice de la diapositive actuelle
    }

    return (
        <div>
            {window.innerWidth > 950 ?
                <FirstSlide
                    type="First"
                    image={[BufferONE, PickONE, CrossONE, StoreONE]}
                    title={[i18n.t("Slider.Title"), i18n.t("Slider.BUFFER-ONE.title"), i18n.t("Slider.PICK-ONE.title"), i18n.t("Slider.CROSS-ONE.title"), i18n.t("Slider.STORE-ONE.title")]}
                    currentIndex={currentSlide} // Passez currentIndex en tant que prop
                    handleSlideChange={handleSlideChange}
                />
                : null
            }
            <Slider currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
        </div>
    );
};

export default Usage;