import { useTranslation } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slides from "./TestSlide";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import BufferONE from '../assets/img/Buffer-one.png';
import PickONE from '../assets/img/Pick-one.png';
import CrossONE from '../assets/img/Cross-one.png';
import StoreONE from '../assets/img/Store-one.png';
import PickOneVidFR from '../assets/vid/Video_PICK-ONE_-_FR.mp4'
import PickOneVidEN from '../assets/vid/Video_PICK-ONE_-_ENG.mp4'
import BufferOneVidFR from '../assets/vid/Video_BUFFER-ONE_-_FR.mp4'
import BufferOneVidEN from '../assets/vid/Video_BUFFER-ONE_-_ENG.mp4'

const Slider = ({ currentSlide, setCurrentSlide }) => {

    const i18n = useTranslation();

    const slideData = [
        {
            id: "BUFFER-ONE", img: window.innerWidth > 950 ? require('../assets/img/back.jpg') : require('../assets/img/Buffer-one-mob.jpg'),
            title: <Slides
                type="1"
                image={BufferONE}
                video ={localStorage.getItem("lng") === "fr" ? BufferOneVidFR : BufferOneVidEN}
                title={[i18n.t("Slider.BUFFER-ONE.title"), i18n.t("Slider.BUFFER-ONE.title 2")]}
                app={[i18n.t("Slider.BUFFER-ONE.application"), i18n.t("Slider.BUFFER-ONE.application 2"), i18n.t("Slider.BUFFER-ONE.application 3")]}
                size={[i18n.t("Slider.BUFFER-ONE.size")]}
                feat={[i18n.t("Slider.BUFFER-ONE.features"), i18n.t("Slider.BUFFER-ONE.features 2")]}
                gain={[i18n.t("Slider.BUFFER-ONE.gains generated"), i18n.t("Slider.BUFFER-ONE.gains generated 2"), i18n.t("Slider.BUFFER-ONE.gains generated 3")]} />
        },
        {
            id: "PICK-ONE", img: window.innerWidth > 950 ? require('../assets/img/back.jpg') : require('../assets/img/Pick-one-mob.jpg'),
            title: <Slides
                type="2"
                image={PickONE}
                video ={localStorage.getItem("lng") === "fr" ? PickOneVidFR : PickOneVidEN}
                title={[i18n.t("Slider.PICK-ONE.title"), i18n.t("Slider.BUFFER-ONE.title 2")]}
                app={[i18n.t("Slider.PICK-ONE.application"), i18n.t("Slider.PICK-ONE.application 2")]}
                size={[i18n.t("Slider.PICK-ONE.size")]}
                feat={[i18n.t("Slider.PICK-ONE.features"), i18n.t("Slider.PICK-ONE.features 2")]}
                gain={[i18n.t("Slider.PICK-ONE.gains generated"), i18n.t("Slider.PICK-ONE.gains generated 2")]} />
        },

        {
            id: "CROSS-ONE", img: window.innerWidth > 950 ? require('../assets/img/back.jpg') : require('../assets/img/Cross-one-mob.jpg'),
            title: <Slides
            type="1"
            image={CrossONE}
            title={[i18n.t("Slider.CROSS-ONE.title"), i18n.t("Slider.BUFFER-ONE.title 2")]}
            app={[i18n.t("Slider.CROSS-ONE.application")]}
            size={[i18n.t("Slider.CROSS-ONE.size"), i18n.t("Slider.CROSS-ONE.size 2")]}
            feat={[i18n.t("Slider.CROSS-ONE.features"), i18n.t("Slider.CROSS-ONE.features 2")]}
            gain={[i18n.t("Slider.CROSS-ONE.gains generated"), i18n.t("Slider.CROSS-ONE.gains generated 2"), i18n.t("Slider.CROSS-ONE.gains generated 3")]} />
        },
        {
            id: "STORE-ONE", img: window.innerWidth > 950 ? require('../assets/img/back.jpg') : require('../assets/img/Store-one-mob.jpg'),
            title: <Slides
                type="2"
                image={StoreONE}
                title={[i18n.t("Slider.STORE-ONE.title"), i18n.t("Slider.BUFFER-ONE.title 2")]}
                app={[i18n.t("Slider.STORE-ONE.application"), i18n.t("Slider.STORE-ONE.application 2"), i18n.t("Slider.STORE-ONE.application 3")]}
                size={[i18n.t("Slider.STORE-ONE.size")]}
                feat={[i18n.t("Slider.STORE-ONE.features"), i18n.t("Slider.STORE-ONE.features 2")]}
                gain={[i18n.t("Slider.STORE-ONE.gains generated"), i18n.t("Slider.STORE-ONE.gains generated 2")]} />
        }
    ];

    return (
        <div>
            <Carousel className="sliderSize" infiniteLoop showStatus={false} showThumbs={false}
                selectedItem={currentSlide} // Utilisez selectedItem pour définir la diapositive actuelle
                onChange={(index) => setCurrentSlide(index)}
                renderArrowPrev={(clickHandler) => {
                    return (
                        <div className="sliderArrow leftArrow" onClick={clickHandler}>
                            <FaChevronLeft />
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler) => {
                    return (
                        <div className="sliderArrow rightArrow" onClick={clickHandler}>
                            <FaChevronRight />
                        </div>
                    );
                }}
                renderIndicator={(onClickHandler, isSelected, index) => {
                    const style = isSelected
                    ? "sliderIndicatorsClicked"
                    : "sliderIndicators";
                    return (
                        <button className={style} onClick={onClickHandler} onKeyDown={onClickHandler}>
                            {slideData[index].id}
                        </button>
                    );
                }}
            >
                {slideData.map((slide) => (
                    <div key={slide.id}>
                        <img src={slide.img} alt="" />
                        <div>{slide.title}</div>
                    </div>
                )
                )}
            </Carousel>
        </div>
    );
};

export default Slider;