import { useTranslation } from "react-i18next";

const Legal = () => {

    const i18n = useTranslation();

    return (
        <div className="legalBlock">
            <h1>{i18n.t("Legal.title")}</h1>
            <br />
            <h2>{i18n.t("Legal.sub title")}</h2>
            <h2>{i18n.t("Legal.sub title 2")}</h2>
            <h2>{i18n.t("Legal.sub title 3")}</h2>
            <h2>{i18n.t("Legal.sub title 4")}</h2>
            <h2>{i18n.t("Legal.sub title 5")}</h2>
            <h2>{i18n.t("Legal.sub title 6")}</h2>
            <br /> <br /> <br />
            <h1>{i18n.t("Legal.title 2")}</h1>
            <br />
            <p>{i18n.t("Legal.paragraph 2")}</p>
            <br /> <br /> <br />
            <h1>{i18n.t("Legal.title 3")}</h1>
            <br />
            <p>{i18n.t("Legal.paragraph 3")}</p>
        </div>
    );
};

export default Legal;