import React, { useRef } from "react";

const FirstSlide = (props) => {
    const { type, image, title, handleSlideChange } = props;

    const videoBgContentRef = useRef(null);

    const handleClick = () => {
        if (videoBgContentRef.current) {
            const videoBgContentHeight = videoBgContentRef.current.clientHeight;
            window.scroll({
                top: videoBgContentHeight - window.innerHeight * 0.1,
                behavior: "smooth"
            });
        }
    }

    const handleIndicatorClick = (index) => {
        handleSlideChange(index);
    };

    return (
        <div>
            <h1 className="sliderBanderino" data-aos="fade">{title[0]}</h1>
            <div className={"sliderBlock" + type} ref={videoBgContentRef}>
                <div id="col" data-aos="fade" data-aos-duration="2000">
                    <img src={image[0]} alt="" data-aos="fade" data-aos-duration="3000" onClick={() => {handleClick(); handleIndicatorClick(0)}}/>
                    <h1 className={"sliderTitle" + type}>BUFFER-ONE</h1>
                    <h1 className={"sliderBand" + type}>{title[1]}</h1>
                </div>
                <div id="col" data-aos="fade" data-aos-duration="2000">
                    <img src={image[1]} alt="" data-aos="fade" data-aos-duration="3000" onClick={() => {handleClick(); handleIndicatorClick(1)}}/>
                    <h1 className={"sliderTitle" + type}>PICK-ONE</h1>
                    <h1 className={"sliderBand" + type}>{title[2]}</h1>
                </div>
                <div id="col" data-aos="fade" data-aos-duration="2000">
                    <img src={image[2]} alt="" data-aos="fade" data-aos-duration="3000" onClick={() => {handleClick(); handleIndicatorClick(2)}}/>
                    <h1 className={"sliderTitle" + type}>CROSS-ONE</h1>
                    <h1 className={"sliderBand" + type}>{title[3]}</h1>
                </div>
                <div id="col" data-aos="fade" data-aos-duration="2000">
                    <img src={image[3]} alt="" data-aos="fade" data-aos-duration="3000" onClick={() => {handleClick(); handleIndicatorClick(3)}}/>
                    <h1 className={"sliderTitle" + type}>STORE-ONE</h1>
                    <h1 className={"sliderBand" + type}>{title[4]}</h1>
                </div>
            </div>
        </div>
    );
};

export default FirstSlide;
