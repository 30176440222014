import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Footer = () => {

    const i18n = useTranslation();

    return (
        <div className="footerBlock">
            <h1>Galam Robotics © 2023 - All rights reserved</h1>
            <h1><NavLink to="/legal">{i18n.t("Legal.name")}</NavLink></h1>
        </div>
    );
};

export default Footer;