/*import { useTranslation } from "react-i18next";

const Contact = () => {

    const i18n = useTranslation();

    return (
        <div className="contactBlock">
            <form>
                <h1>{i18n.t("Contact us")}</h1>
                <div>
                    <input
                        className="contactContent"
                        id="name"
                        name="name"
                        placeholder={i18n.t("Contact.name")}
                        type="text"
                        autoComplete="true"
                        required
                    />
                </div>
                <div>
                    <input
                        className="contactContent"
                        id="email"
                        name="email"
                        placeholder={i18n.t("Contact.e-mail")}
                        type="email"
                        autoComplete="true"
                        required
                    />
                </div>
                <div>
                    <textarea
                        className="contactContent"
                        id="message"
                        name="message"
                        placeholder={i18n.t("Contact.message")}
                        rows="5"
                        required
                    ></textarea>
                </div>
                <button type="submit">
                    {i18n.t("Contact.send")}
                </button>
            </form>
        </div>
    );
};

export default Contact;*/

import { useForm, ValidationError } from '@formspree/react';
import { useTranslation } from "react-i18next";

const Contact = () => {

    const i18n = useTranslation();

    const [state, handleSubmit] = useForm("xbkwqvrm");
    if (state.succeeded) {
        window.location.reload(false);
    }
    return (
        <div className="contactBlock">
            <form onSubmit={handleSubmit}>
                <h1>{i18n.t("Contact us")}</h1>
                <div>
                <label htmlFor="name">
                </label>
                <input
                    className="contactContent"
                    placeholder={i18n.t("Contact.name")}
                    id="name"
                    type="name"
                    name="name"
                    required
                />
                </div>
                <div>
                <label htmlFor="email">
                </label>
                <input
                    className="contactContent"
                    placeholder={i18n.t("Contact.e-mail")}
                    id="email"
                    type="email"
                    name="email"
                    required
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
                </div>
                <div>
                <textarea className="contactContent"
                    placeholder={i18n.t("Contact.message")}
                    id="message"
                    name="message"
                    required
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
                </div>
                <button type="submit" disabled={state.submitting}>
                    {i18n.t("Contact.send")}
                </button>
            </form>
        </div>
    );
}

export default Contact;