const Thumbnail = ({ img_title, title, paragraph, animation }) => (
    <div className="thumbRow bg1" data-aos={animation}>
        <div className="thumbRowTitle">
            <img src={img_title} alt="" />
            <h1>{title}</h1>
        </div>
        <p>{paragraph}</p>
    </div>
);

export default Thumbnail;