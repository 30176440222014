import { useTranslation } from "react-i18next";

const Slides = (props) => {

    const { type, image,video, title, app, size, feat, gain } = props

    const i18n = useTranslation();

    //<h1 className={"sliderBand" + type}>{title[0]} <br /> {title[1]}</h1>

    return (
        <div>
            <div className={"sliderBlock sliderBlock" + type}>
                <div className={"sliderGrid" + type} id="col" data-aos="fade-left">
                    <h2 className="sliderRow" >{i18n.t("Slider.Application")}</h2>
                    <div className="sliderRow" >
                        {app.map((paragraph, i) => (
                            <p key={i}>{i > 0 && <br />}{paragraph}</p>
                        ))}
                    </div>
                    <h2 className="sliderRow" >{i18n.t("Slider.Size")}</h2>
                    <div className="sliderRow" >
                        {size.map((paragraph, i) => (
                            <p key={i}>{i > 0 && <br />}{paragraph}</p>
                        ))}
                    </div>
                    <h2 className="sliderRow" >{i18n.t("Slider.Features")}</h2>
                    <div className="sliderRow" >
                        {feat.map((paragraph, i) => (
                            <p key={i}>{i > 0 && <br />}{paragraph}</p>
                        ))}
                    </div>
                    <h2 className="sliderRow">{i18n.t("Slider.Gains generated")}</h2>
                    <div className="sliderRow" >
                        {gain.map((paragraph, i) => (
                            <p key={i}>{i > 0 && <br />}{paragraph}</p>
                        ))}
                    </div>
                </div>
                <div id="col">
                    {
                        video == null ? <img src={image} alt="" data-aos="fade" data-aos-duration="3000"/>
                        :<video className="itemMedia" controls={true} poster={image}><source src={video}/></video> 
                    }
                    <h1 className={"sliderBand" + type} data-aos="fade-up">{title[0]} <br /> {title[1]}</h1>
                </div>
            </div>
        </div>
    );
};

export default Slides;