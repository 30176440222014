import { useTranslation } from "react-i18next";
import React, { useRef } from "react";

import video from '../assets/vid/teaser.mp4'
import image from '../assets/img/galam.png'

const Videobg = () => {

    const i18n = useTranslation();
    const videoBgContentRef = useRef(null);

    const handleClick = () => {
        if (videoBgContentRef.current) {
            const videoBgContentHeight = videoBgContentRef.current.clientHeight;
            window.scroll({
                top: videoBgContentHeight - window.innerHeight * 0.1,
                behavior: "smooth"
            });
        }
    }

    return (
        <div className="videoBgBlock">
            <video autoPlay loop muted playsinline="" data-wf-ignore="true"><source src={video} data-wf-ignore="true" /></video>
            <div className="videoBgOverlay"></div>
            <div className="videoBgContent" ref={videoBgContentRef}>
                <img src={image} alt="logo" /> <br /> <br />
                <h1>{i18n.t("Future is invented here")} </h1>
                <div className="videoBgScroll">
                    <svg width="50px" height="30px" onClick={handleClick}>
                        <path
                            stroke="#ffffff"
                            fill="none"
                            strokeWidth="2px"
                            d="M3.000,5.000 L15.000,18.000 L28.000,5.000"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Videobg;
